/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import FormPanel from 'components/FormPanel';
import ItemLabel from 'components/ItemLabel';
import RadioGroup from 'components/RadioGroup';
import { getExperienceForm, createExperienceTest } from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import { useLocation } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { checkFieldIsWarning } from 'containers/CreateAccountForm/utils';
import { CreateAccountSteps } from 'constant/createAccount';
import { RouterPath } from 'type/common';
import { StyledLoadingWrap } from '../style';
import { StyledRadioWrap, StyledQuestionWrap, StyledContentWrap } from './style';

interface QuestionProps {
  id: string;
  type: string;
  name: string;
  placeHolder: string;
  required: boolean;
  data: {
    label: string;
    value: string;
  }[];
}

const PreferenceExperience = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const { control, setValue } = useFormContext();
  const [tradingExperience, setTradingExperience] = useState([]);
  const { rejectWarningFields, updateFormIsLoading } = useCreateCorporateAccountFlow();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    updateFormIsLoading(true);
    const accountID = localStorage.getItem('accountID') || '';
    getExperienceForm(accountID).then((res) => {
      if (res && res.data.length > 0) {
        setValue('rg227_test_id', res?.data[0]?.testId);
        setTradingExperience(res?.data[0]?.questions || []);
        updateFormIsLoading(false);
        setIsLoading(false);
      } else if (location.pathname === RouterPath.createAccountCorporateAccount) {
        createExperienceTest(accountID).then((response) => {
          setValue('rg227_test_id', response?.data[0]?.testId);

          setTradingExperience(response?.data[0]?.questions || []);
        }).finally(() => {
          updateFormIsLoading(false);
          setIsLoading(false);
        });
      } else {
        updateFormIsLoading(false);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <div>
      <FormPanel
        id="Trading Experience"
        title={t('tradingExperience')}
        description={t('answerTenQuestion')}
      >
        {
          isLoading
            ? (
              <StyledLoadingWrap>
                <CircularProgress />
              </StyledLoadingWrap>
            )
            : (
              <StyledContentWrap>
                {tradingExperience.map((tradingQuesObj: QuestionProps, index) => {
                  if (tradingQuesObj.type === 'radio') {
                    return (
                      <StyledQuestionWrap key={tradingQuesObj.id}>
                        <ItemLabel id={tradingQuesObj.id} title={`${index + 1}. ${tradingQuesObj.name}`}>
                          <StyledRadioWrap>
                            <Controller
                              control={control}
                              name={tradingQuesObj.id}
                              rules={{ required: true }}
                              render={({
                                field: {
                                  onChange, value
                                }
                              }) => (
                                <RadioGroup
                                  options={tradingQuesObj.data}
                                  groupName={tradingQuesObj.id}
                                  onChange={onChange}
                                  value={value}
                                  disabled={checkFieldIsWarning(rejectWarningFields, CreateAccountSteps.Experience, tradingQuesObj.id)}
                                />
                              )}
                            />
                          </StyledRadioWrap>
                        </ItemLabel>
                      </StyledQuestionWrap>
                    );
                  }

                  return (
                    <StyledQuestionWrap key={tradingQuesObj.id}>
                      <ItemLabel id={tradingQuesObj.id} title={`${index + 1}. ${tradingQuesObj.name}`}>
                        <StyledRadioWrap>
                          <Controller
                            control={control}
                            name={tradingQuesObj.id}
                            rules={{ required: true }}
                            render={({
                              field: {
                                onChange, value
                              }
                            }) => (
                              <RadioGroup
                                options={tradingQuesObj.data}
                                groupName={tradingQuesObj.id}
                                optionType="button"
                                onChange={onChange}
                                value={value}
                                disabled={checkFieldIsWarning(rejectWarningFields, CreateAccountSteps.Experience, tradingQuesObj.id)}
                              />
                            )}
                          />
                        </StyledRadioWrap>
                      </ItemLabel>
                    </StyledQuestionWrap>
                  );
                })}
              </StyledContentWrap>
            )
        }
      </FormPanel>
    </div>
  );
};

export default PreferenceExperience;
