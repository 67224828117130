/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-alert */
import PersonDetails from 'containers/CreateAccountForm/components/PersonDetails';
import TradingEmployment from 'containers/CreateAccountForm/components/TradingEmployment';
import TradingExperience from 'containers/CreateAccountForm/components/TradingExperience';
import TermsAndConditions from 'containers/CreateAccountForm/components/TermsAndConditions';
import { useAppSelector, useAppDispatch } from 'store';
import { nextStep, assignStep } from 'containers/CreateAccountForm/slices';
import StepButtons from 'containers/CreateAccountForm/components/StepButtons';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import ErrorHandler from 'utils/ErrorHandler';
import { getSanctionedCountryList } from 'api/v1/user';
import { useTranslation } from 'react-i18next';
import {
  updatePersonalAccount,
  getAccountByID,
  getRejectMessages,
  getAccountPortalLoginURL
} from 'api/v1/account';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateAccountFlow } from 'store/context/hooks';
import {
  PERSONAL_ACCOUNT_STEPS,
  CreateAccountSteps,
  Countries
} from 'constant/createAccount';
import BackdropSpinner from 'components/BackdropSpinner';
import CircularProgress from '@mui/material/CircularProgress';
import PopUp from 'components/PopUp';
import Button from '@mui/material/Button';
import { StyledBtnWrap } from 'containers/SelectAccountTypePanel/style';
import { isEmpty } from 'lodash';

import { setAccountStatus } from 'containers/Pages/slices';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import {
  AccountStatus, AccountType, Regulation, RouterPath
} from 'type/common';
import ConfirmId from './components/ConfirmId';
import {
  StyledCreateAccountFrom, StyledLoadingContainer, SubmitLoadingWrap, SubmitLoadingContent
} from './style';

interface FormInfo {
  [key: string]: string | number;
}

interface IncompleteData {
  [key: string]: string | number | boolean;
}

interface RejectDataProps {
  [key: string]: string | number | boolean;
}

interface RejectMessageProps {
  [key: string]: {
    warning_message: string;
    warning_value: string | number | boolean;
  };
}
interface RejectStatusProps {
  [key: string]: {
    valid: boolean;
    isWarning: boolean;
  };
}

const CreatAccountFrom = (): JSX.Element => {
  const [initLoading, setInitLoading] = useState(true);
  const currentStepIndex = useAppSelector((state) => state.createAccountState.step);
  const dispatch = useAppDispatch();
  const methods = useForm({ mode: 'all' });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [popupErr, setPopUpErr] = useState<string>('');
  const [isRejectFlowStarted, setIsRejectFlowStarted] = useState(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('registrationForm');
  const selectedCountry = localStorage.getItem('Individual_Account_Country');
  const {
    steps,
    showTip,
    rejectStepStatus,
    rejectWarningFields,
    updatePOACertification,
    updateSteps,
    updateMethod,
    updateFlow,
    updateRejectStepStatus,
    updateRejectWarningFields,
    updateSanctionedCountriesInfo
  } = useCreateAccountFlow();

  const navigate = useNavigate();
  const accountID = String(localStorage.getItem('accountID'));

  const initIncompleteFlow = (incompleteData: IncompleteData): void => {
    const dataObj = { ...incompleteData };
    const curFlow = dataObj.country === Countries.AUS
      ? Regulation.ASIC
      : Regulation.VFSC;
    const curMethod = dataObj.jumio_verified === 0 ? 'Traditional' : 'AI';
    let assignedStepName = dataObj.form_step;
    let usingSteps;
    updateFlow(curFlow);
    updateMethod(curMethod);

    if (curMethod === 'Traditional') {
      if (curFlow === Regulation.ASIC) {
        updateSteps(PERSONAL_ACCOUNT_STEPS.TraditionASIC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.TraditionASIC;
      } else {
        updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.NormalVFSC;
      }
    }

    if (curMethod === 'AI') {
      if (curFlow === Regulation.VFSC) {
        if (dataObj.require_poa) {
          updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
          usingSteps = PERSONAL_ACCOUNT_STEPS.NormalVFSC;
        } else {
          updateSteps(PERSONAL_ACCOUNT_STEPS.AIVFSC);
          usingSteps = PERSONAL_ACCOUNT_STEPS.AIVFSC;
        }
      } else if (dataObj.require_poa) {
        updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
        usingSteps = PERSONAL_ACCOUNT_STEPS.AIASICPOA;
      } else {
        updateSteps(PERSONAL_ACCOUNT_STEPS.AIASIC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.AIASIC;
      }
    }

    Object.entries(dataObj).forEach(([key, value]) => {
      switch (key) {
        case 'form_step':
        case 'id':
        case 'email':
        case 'account_id':
        case 'workflow_execution_id':
        case 'ai_pass':
        case 'jumio_verified':
          break;
        case 'zip_code': {
          methods.setValue(key, showTip ? value : null);
          break;
        }
        case 'trading_platform': {
          const isInitial = !dataObj.funding_currency && !dataObj.leverage;
          methods.setValue(key, isInitial ? null : value);
          break;
        }
        case 'terms_and_conditions': {
          const tncObj = JSON.parse(String(value));
          if (tncObj !== undefined && tncObj !== null) {
            Object.entries(tncObj).forEach(([tncKey, val]) => methods.setValue(tncKey, val));
          }
          break;
        }
        case 'require_poa': {
          updatePOACertification(value as boolean);
          break;
        }
        default: {
          methods.setValue(key, value);
          break;
        }
      }
    });

    if (assignedStepName === CreateAccountSteps.PersonalAccountCreated) {
      assignedStepName = CreateAccountSteps.TradingAndEmployment;
    }

    const stepNum = usingSteps?.indexOf(String(assignedStepName));

    dispatch(assignStep({ step: stepNum }));
  };

  const initRejectFlow = (
    RejectData: RejectDataProps,
    RejectMessage: RejectMessageProps
  ): void => {
    const curFlow = RejectData.country === Countries.AUS
      ? Regulation.ASIC
      : Regulation.VFSC;
    const curMethod = RejectData.jumio_verified === 0 ? 'Traditional' : 'AI';
    const needToUploadID = !isEmpty(RejectMessage.v2_upload_documents);
    let updateCurrentStep = false;
    let stepNum = 0;
    let usingSteps;
    updateFlow(curFlow);
    updateMethod(curMethod);

    if (curMethod === 'Traditional') {
      if (curFlow === Regulation.ASIC) {
        updateSteps(PERSONAL_ACCOUNT_STEPS.TraditionASIC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.TraditionASIC;
      } else {
        updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.NormalVFSC;
      }
    }

    if (curMethod === 'AI' && !needToUploadID) {
      if (curFlow === Regulation.VFSC) {
        updateSteps(PERSONAL_ACCOUNT_STEPS.AIVFSC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.AIVFSC;
      } else if (RejectData.require_poa) {
        updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
        usingSteps = PERSONAL_ACCOUNT_STEPS.AIASICPOA;
      } else {
        updateSteps(PERSONAL_ACCOUNT_STEPS.AIASIC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.AIASIC;
      }
    }
    if (curMethod === 'AI' && needToUploadID) {
      if (curFlow === Regulation.VFSC) {
        updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.NormalVFSC;
      } else {
        updateSteps(PERSONAL_ACCOUNT_STEPS.TraditionASIC);
        usingSteps = PERSONAL_ACCOUNT_STEPS.TraditionASIC;
      }
    }

    Object.keys(RejectData).forEach((eachKey) => {
      if (
        eachKey !== 'form_step'
        && eachKey !== 'id'
        && eachKey !== 'email'
        && eachKey !== 'terms_and_conditions'
        && eachKey !== 'ai_pass'
        && eachKey !== 'require_poa'
        && eachKey !== 'jumio_verified'
      ) {
        methods.setValue(eachKey, RejectData[eachKey]);
      }
      if (eachKey === 'terms_and_conditions') {
        const tncObj = JSON.parse(String(RejectData.terms_and_conditions));
        if (tncObj !== undefined && tncObj !== null) {
          Object.keys(tncObj).forEach((eachTerm) => {
            methods.setValue(eachTerm, tncObj[eachTerm]);
          });
        }
      }
    });
    usingSteps?.forEach((item, index) => {
      if (
        RejectMessage[item]
        && !updateCurrentStep
        && !isEmpty(RejectMessage[item])
      ) {
        updateCurrentStep = true;
        stepNum = index;
      }
    });
    dispatch(assignStep({ step: stepNum }));
  };

  // Common useEffect
  useEffect(() => {
    dispatch(assignStep({ step: 0 }));
    methods.setValue('account_type', AccountType.personal);
    methods.setValue('country', selectedCountry);

    const currentFlow = selectedCountry === Countries.AUS
      ? Regulation.ASIC
      : Regulation.VFSC;
    updateFlow(currentFlow);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  useEffect(() => {
    if (searchParams.get('status') === 'Reject') return;
    if (showTip && accountID) {
      ErrorHandler(getAccountByID(accountID), dispatch).then((res) => {
        if (res.status === 200) {
          initIncompleteFlow(res.data);
        }
      });
    }
  }, [showTip]);

  // Reject flow
  useEffect(() => {
    if (searchParams.get('status') !== 'Reject') return;
    if (accountID) {
      const requestOne = getAccountByID(accountID);
      const requestTwo = getRejectMessages(accountID);
      Promise.all([requestOne, requestTwo])
        .then((res) => {
          if (res[0].status === 200 && res[0].data.status === AccountStatus.Knockout) {
            throw new Error(AccountStatus.Knockout);
          }
          initRejectFlow(res[0].data, res[1].data);
          updateRejectWarningFields(res[1].data);
        })
        .catch((err) => {
          if (err.message === AccountStatus.Knockout) {
            navigate(RouterPath.Home, { replace: true });
          }
        })
        .finally(() => {
          setInitLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('status') !== 'Reject' || isRejectFlowStarted) return;
    const uploadFiles = rejectWarningFields[CreateAccountSteps.ConfirmID];
    if (uploadFiles) {
      uploadFiles.forEach((items) => {
        Object.keys(items).forEach((key) => {
          methods.setValue(key, items[key].warning_value);
        });
      });
    }

    const obj = {} as RejectStatusProps;
    steps.forEach((step) => {
      if (rejectWarningFields[step] && !isEmpty(rejectWarningFields[step])) {
        for (let j = 0; j < rejectWarningFields[step].length; j += 1) {
          const fieldsArr = Object.keys(rejectWarningFields[step][j]);
          for (let i = 0; i < fieldsArr.length; i += 1) {
            if (
              rejectWarningFields[step][j][fieldsArr[i]].warning_value
              === methods.getValues(fieldsArr[i])
            ) {
              if (rejectStepStatus[step] && !rejectStepStatus[step].isWarning) {
                obj[step] = {
                  valid: rejectStepStatus[step].valid,
                  isWarning: true
                };
              }
              if (!rejectStepStatus[step]) {
                obj[step] = { valid: true, isWarning: true };
              }
              setIsRejectFlowStarted(true);
              return;
            }
          }
        }
      }
      if (rejectStepStatus[step]) {
        obj[step] = { valid: rejectStepStatus[step].valid, isWarning: false };
      } else obj[step] = { valid: true, isWarning: false };
    });
    updateRejectStepStatus(obj);
  }, [steps, rejectWarningFields, isRejectFlowStarted]);

  // Veriff handler + Incomplete/Normal flow
  useEffect(() => {
    if (searchParams.get('status') === 'Reject') return;

    // Incomplete
    getAccountByID(accountID)
      .then((res) => {
        const resData = res.data;
        if (res.status === 200) {
          if (resData.status === AccountStatus.Knockout) {
            throw new Error(AccountStatus.Knockout);
          }
          initIncompleteFlow(resData);
        }
        return resData;
      })
      .then(() => {
        getSanctionedCountryList().then((res) => {
          if (res.data) updateSanctionedCountriesInfo(res.data);
        }).finally(() => {
          setInitLoading(false);
        });
      }).catch((err) => {
        if (err.message === AccountStatus.Knockout) {
          navigate(RouterPath.Home, { replace: true });
        }
      });
  }, [accountID, navigate, searchParams]);

  const renderForm = (): JSX.Element => {
    switch (steps[currentStepIndex]) {
      case CreateAccountSteps.TradingAndEmployment:
        return <TradingEmployment />;
      case CreateAccountSteps.PersonalInformation:
        return <PersonDetails />;
      case CreateAccountSteps.Experience:
        return <TradingExperience />;
      case CreateAccountSteps.TermsAndConditions:
        return <TermsAndConditions />;
      case CreateAccountSteps.ConfirmID:
        return <ConfirmId />;
      default:
        return <div />;
    }
  };

  const updateAccount = (
    personalData: FormInfo,
    accountId: string,
    formStep: string,
    curStepName: string
  ): void => {
    // formStep -> nextStepName
    // currentStep -> currentStepName
    if (currentStepIndex === steps.length - 1) {
      setIsSubmit(true);
    }
    setSubmitLoading(true);

    updatePersonalAccount(personalData, accountId, formStep, curStepName)
      .then((res) => {
        if (res && res.status === 200) {
          if (currentStepIndex === steps.length - 1) {
            ErrorHandler(getAccountByID(accountID), dispatch).then((autoActiveRes) => {
              if (autoActiveRes.status === 200) {
                const { status } = autoActiveRes.data;
                if (status === AccountStatus.Auto_Active) {
                  ErrorHandler(
                    getAccountPortalLoginURL(accountID, 'account-management/my-trading-account'),
                    dispatch
                  )
                    .then((loginRes) => {
                      if (loginRes && loginRes.status === 200) {
                        localStorage.removeItem('accountID');
                        window.location.assign(`${loginRes.data.login_url}&trigger_action=true&popupopen=true`);
                      }
                    });
                } else {
                  navigate(RouterPath.createAccountPersonalAccountThankYou, { replace: true, state: { ai: personalData.jumio_verified === 1 } });
                  localStorage.removeItem('accountID');
                  dispatch(setAccountStatus({ accountStatus: AccountStatus.Pending }));
                }
              }
            });
            return;
          }
          dispatch(nextStep());
        }
      })
      .catch((err) => {
        if (err.response.status === 501 && currentStepIndex === steps.length - 1) {
        // auto active && open mt account fail
          navigate(RouterPath.createAccountPersonalAccountThankYou, { replace: true, state: { ai: personalData.jumio_verified === 1 } });
          localStorage.removeItem('accountID');
          dispatch(setAccountStatus({ accountStatus: AccountStatus.Pending }));
        } else if (err.response.status === 422 && err.response.data.message === AccountStatus.Knockout) {
          dispatch(setAccountStatus({ accountStatus: AccountStatus.Knockout }));
          navigate(RouterPath.Home, { replace: true });
        } else {
          alert(err.response?.data?.message);
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onSubmit: SubmitHandler<React.SyntheticEvent> = (e) => {
    e.preventDefault();
    const data = methods.getValues();

    if (searchParams.get('status') === 'Reject') {
      ['id_front', 'id_back', 'proof_of_address', 'other_documents'].forEach(
        (key) => {
          if (typeof data[key] === 'string') delete data[key];
        }
      );
    }

    const submitData = {
      ...data,
      entry_url: `${DOMAIN_CONFIG.origin}/rg227/${
        localStorage.getItem('accountID') || ''
      }`
    };

    const theNextStepName = currentStepIndex === steps.length - 1
      ? steps[currentStepIndex]
      : steps[currentStepIndex + 1];
    if (accountID) {
      // PUT
      updateAccount(
        submitData,
        accountID,
        theNextStepName,
        steps[currentStepIndex]
      );
    }
  };

  const SubmitContent = (
    <SubmitLoadingWrap>
      <CircularProgress />
      <div aria-label="Pop Up Content" className="content-wrap">
        <SubmitLoadingContent>
          {t('SubmitLoadingDesc')}
        </SubmitLoadingContent>
        <SubmitLoadingContent>
          {t('SubmitLoadingWaiting')}
        </SubmitLoadingContent>
      </div>
    </SubmitLoadingWrap>
  );

  return (
    <StyledCreateAccountFrom
      className="StyledCreateAccountFrom"
      showTip={showTip}
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          {initLoading ? (
            <StyledLoadingContainer>
              <CircularProgress className="custom-progress" />
            </StyledLoadingContainer>
          ) : (
            <>
              {renderForm()}
              <StepButtons isSubmitting={submitLoading} />
            </>
          )}
        </form>
      </FormProvider>

      <PopUp
        content={popupErr}
        openModal={popupErr !== ''}
        onClose={() => setPopUpErr('')}
      >
        <StyledBtnWrap>
          <Button aria-label="Pop Up OK" onClick={() => setPopUpErr('')}>
            ok
          </Button>
        </StyledBtnWrap>
      </PopUp>
      <BackdropSpinner open={submitLoading} content={isSubmit ? SubmitContent : undefined} />
    </StyledCreateAccountFrom>
  );
};

export default CreatAccountFrom;
