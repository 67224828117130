/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlurredBox from 'assets/blurredPages/BlurredBox.png';
import BlurredBoxApplicationRejected from 'assets/blurredPages/BlurredBox-ApplicationRejected.png';
import { AccountStatus, AccountType, RouterPath } from 'type/common';
import { useAccountRG227State } from 'store/context/accountRG227Context';
import {
  StyledBlurredPopUpWrap,
  StyledMessageWrap,
  StyledLine,
  StyledBoxImg,
  BoxImg,
  StyledActionGroup,
  StyledBlurredPopUpImageWrapper,
  StyleNewBlurredPopUpWrap,
  StyledBlurredPopUpContentWrapper,
  StyledBlurredPopUpTitle,
  StyledBlurredPopUpDescription
} from './styled';

const accountStatusMessageMap: Record<AccountStatus, string> = {
  [AccountStatus.Active]: '',
  [AccountStatus.Pending]: 'processingApplication',
  [AccountStatus.Approve]: 'processingApplication',
  [AccountStatus.Reject]: 'rejectApplication',
  [AccountStatus.Blocked]: '',
  [AccountStatus.Incomplete]: 'openALiveAccountToday',
  [AccountStatus.Resubmitted]: 'processingApplication',
  [AccountStatus.Auto_Active]: '',
  [AccountStatus.Knockout]: 'knockout',
  [AccountStatus.noAccount]: 'openALiveAccountToday'
};

const navigationPath = {
  [AccountType.personal]: RouterPath.createAccountPersonalAccount,
  [AccountType.corporate]: RouterPath.createAccountCorporateAccount,
  [AccountType.joint]: RouterPath.createAccountJointAccount
};

const BlurredPopUpCard = (): JSX.Element => {
  const { t } = useTranslation('blurredPopUpCard');
  const { t: commonT } = useTranslation('common');

  const navigate = useNavigate();
  const accountStatus = useAppSelector(
    (state) => state.pageState.accountStatus
  );
  const createAccountStatus = useAppSelector(
    (state) => state.pageState.createAccountStatus
  );
  const { isBlockedByRg227, canRetryRg227, accountId } = useAccountRG227State();

  const canDoRg227 = canRetryRg227 && !isBlockedByRg227;

  const message = accountStatus && accountStatusMessageMap[accountStatus];

  const handleIncomplete = (): void => {
    if (createAccountStatus?.isOnBoardingMethodSelecting) {
      navigate(RouterPath.createAccountVerifyIdentity);
      return;
    }
    navigate(navigationPath[createAccountStatus.type]);
  };

  const handleReject = (): void => {
    navigate(`${navigationPath[createAccountStatus.type]}?status=Reject`);
  };

  return (
    <div className="BlurredPopUpCard" aria-label="Blurred page popup">
      <div className="BlurredPopUpInside">
        {accountStatus === AccountStatus.Knockout ? (
          <StyleNewBlurredPopUpWrap>
            <StyledBlurredPopUpImageWrapper>
              <img src={BlurredBoxApplicationRejected} alt="blurredBoxApplicationRejected" draggable="false" />
            </StyledBlurredPopUpImageWrapper>
            <StyledBlurredPopUpContentWrapper>
              <StyledBlurredPopUpTitle aria-label="blurred pop up card title">
                {t('title.knockout')}
              </StyledBlurredPopUpTitle>
              <StyledBlurredPopUpDescription
                aria-label="blurred pop up card description knockout_1"
              >
                <span dangerouslySetInnerHTML={{
                  __html: t('content.knockout_1', {
                    tmd: `<a href='https://docs.acygroup.com/legal/acy-securities-target-market-determination.pdf' 
                      target='_blank' rel='noopenerv noreferrer'>${t('content.tmd')}</a>`,
                    interpolation: { escapeValue: false }
                  })
                }}
                />
              </StyledBlurredPopUpDescription>
              <StyledBlurredPopUpDescription aria-label="blurred pop up card description knockout_2">
                {t('content.knockout_2')}
              </StyledBlurredPopUpDescription>
              <StyledBlurredPopUpDescription aria-label="blurred pop up card description knockout_3">
                {t('content.knockout_3')}
              </StyledBlurredPopUpDescription>
            </StyledBlurredPopUpContentWrapper>
          </StyleNewBlurredPopUpWrap>
        ) : (
          <StyledBlurredPopUpWrap>
            <StyledBoxImg>
              <BoxImg
                src={BlurredBox}
                alt="blurredBox"
                draggable="false"
                aria-label="blurred pop up card image"
              />
            </StyledBoxImg>
            <h3 aria-label="blurred pop up card title">
              {t('title.liveAccountRequired')}
            </h3>
            <StyledLine />
            <StyledMessageWrap>
              {message && (
              <span aria-label="blurred pop up card description">
                {t(`content.${message}`)}
              </span>
              )}
              {accountStatus === AccountStatus.Pending && canDoRg227 && (
              <StyledActionGroup>
                <Button
                  aria-label="retry assessment"
                  onClick={() => navigate(`/rg227/${accountId}`)}
                >
                  {commonT('retryAssessment')}
                </Button>
              </StyledActionGroup>
              )}
              {accountStatus === AccountStatus.Incomplete && (
              <StyledActionGroup>
                <Button
                  aria-label="continue where you left"
                  onClick={handleIncomplete}
                >
                  {commonT('continueWhereYouLeft')}
                </Button>
              </StyledActionGroup>
              )}
              {accountStatus === AccountStatus.Reject && !canDoRg227 && (
              <StyledActionGroup>
                <Button
                  aria-label="finalise your application"
                  onClick={handleReject}
                >
                  {commonT('finaliseYourApplication')}
                </Button>
              </StyledActionGroup>
              )}
              {accountStatus === AccountStatus.noAccount && (
              <StyledActionGroup>
                <Button
                  aria-label="open a live account"
                  onClick={() => navigate(RouterPath.createAccountSelectAccountType)}
                >
                  {commonT('openALiveAccount')}
                </Button>
              </StyledActionGroup>
              )}
            </StyledMessageWrap>
          </StyledBlurredPopUpWrap>
        )}
      </div>
    </div>
  );
};

export default BlurredPopUpCard;
