import { useState, useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import TradingExperience from 'containers/CreateAccountForm/components/TradingExperience';
import Button from 'components/Button';
import { useCreateAccountFlow } from 'store/context/hooks';
import { useLocation, useNavigate } from 'react-router';
import { submitTestAnswer } from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import {
  StyledLButtonsWrap,
  StyledNeedHelpSentence
} from 'containers/CreateAccountForm/components/StepButtons/style';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { AccountStatus, RouterPath } from 'type/common';
import { setAccountStatus } from 'containers/Pages/slices';
import { useAppDispatch } from 'store';

const ExperienceQuestionForm = (): JSX.Element => {
  const [init, setInit] = useState(true);
  const methods = useForm({ mode: 'all' });
  const { formIsLoading, updateFormIsLoading } = useCreateAccountFlow();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('registrationForm');
  const pathArr = location.pathname.split('/');

  useEffect(() => {
    localStorage.setItem('accountID', pathArr[2]);
    setInit(false);
  }, [pathArr]);

  const onSubmit: SubmitHandler<object> = (data) => {
    updateFormIsLoading(true);
    submitTestAnswer(
      data,
      localStorage.getItem('accountID') || '',
      methods.getValues('rg227_test_id'),
      `${DOMAIN_CONFIG.origin}/rg227/${methods.getValues(
        'rg227_test_id'
      )}`
    )
      .then((res) => {
        if (res && res.status === 200) {
          navigate(RouterPath.rg227ThankYou, { replace: true });
        }
      })
      .catch((err) => {
        if (err.response.status === 422 && err.response.data.message === AccountStatus.Knockout) {
          dispatch(setAccountStatus({ accountStatus: AccountStatus.Knockout }));
          navigate(RouterPath.Home, { replace: true });
        }
        if (err.response.status === 423) {
          navigate(RouterPath.rg227Unsuccessful, { replace: true });
        }
      }).finally(() => {
        updateFormIsLoading(false);
      });
  };

  return (
    <FormProvider {...methods}>
      {!init && (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TradingExperience />
          <StyledLButtonsWrap>
            <Button
              className="next-button"
              htmlType="submit"
              disabled={!methods.formState.isValid || formIsLoading}
              aria-label="Submit Button"
            >
              {t('submit')}
            </Button>
            <StyledNeedHelpSentence>
              <span aria-label="Need Help For Practice Questions">{t('needHelp')}</span>
              <a
                target="_blank"
                href={`${DOMAIN_CONFIG.origin}/rg227-practice`}
                rel="noreferrer"
                aria-label="Go To Practice Questions"
              >
                {' '}
                {t('clickHere')}
              </a>
            </StyledNeedHelpSentence>
          </StyledLButtonsWrap>
        </form>
      )}
    </FormProvider>
  );
};

export default ExperienceQuestionForm;
