/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import FormPanel from 'components/FormPanel';
import UploadFile, { ReturnFiles } from 'components/UploadFile';
import { useFormContext, Controller } from 'react-hook-form';
import { uploadIdData, VFSCTraditional, onlyPOAUpload } from 'containers/CreateAccountForm/data/registration';
import { useCreateAccountFlow } from 'store/context/hooks';
import { CreateAccountSteps } from 'constant/createAccount';
import { useTranslation } from 'react-i18next';
import { getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting } from 'containers/CreateAccountForm/utils';
import { useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';
import { Regulation } from 'type/common';
import {
  StyledConfirmedId, StyledUploadWrap
} from './style';
import { StyledContentWrap } from '../style';

const ConfirmId = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const { control, formState: { errors }, getValues } = useFormContext();
  const currentStep = CreateAccountSteps.ConfirmID;
  const {
    method,
    flow,
    rejectWarningFields,
    rejectStepStatus,
    updateRejectStepWarningStatus,
    POACertification
  } = useCreateAccountFlow();

  const [searchParams] = useSearchParams();
  // There is no need to display the popup in manually flow
  // const [showPopup, setShowPopup] = useState(!jumioInfo && searchParams.get('status') !== 'Reject');

  let uploads = uploadIdData;
  // LCK-34
  // SVG customer: If Country of Residence is not equal to the Country of Nationality, show the POA document upload option in Upload Documents page.
  if (method === 'AI' && searchParams.get('status') !== 'Reject' && POACertification) {
    uploads = onlyPOAUpload;
  } else if (flow === Regulation.VFSC && searchParams.get('status') !== 'Reject' && !POACertification) {
    uploads = VFSCTraditional;
  }

  const inputChangeHandler = (id: string, index: number, isCancelRejectUpload = false): void => {
    if (searchParams.get('status') !== 'Reject') return;
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep]) && rejectWarningFields[currentStep][index][id]) {
      if (getValues(id) === rejectWarningFields[currentStep][index][id].warning_value) {
        if (!rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      } else {
        for (let j = 0; j < rejectWarningFields[currentStep].length; j += 1) {
          const fieldsArr = Object.keys(rejectWarningFields[currentStep][j]);
          for (let i = 0; i < fieldsArr.length; i += 1) {
            if (rejectWarningFields[currentStep][j][fieldsArr[i]].warning_value === getValues(fieldsArr[i])) {
              if (!rejectStepStatus[currentStep].isWarning) {
                updateRejectStepWarningStatus(currentStep, true);
              }
              return;
            }
          }
        }
        if (rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, false);
        } else if (isCancelRejectUpload) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      }
    }
  };

  return (
    <StyledConfirmedId>
      <FormPanel id="upload ID" title={t('uploadDocuments')}>
        <StyledContentWrap>
          <StyledUploadWrap>
            {uploads.map((uploadItem) => {
              const warningMsg = getFieldWarningMessage(rejectWarningFields, currentStep, uploadItem.id, 0);
              if (searchParams.get('status') === 'Reject' && !warningMsg) {
                return null;
              }
              return (
                <Controller
                  key={uploadItem.id}
                  control={control}
                  name={uploadItem.id}
                  rules={{ required: warningMsg || searchParams.get('status') !== 'Reject' ? uploadItem.required : false }}
                  render={({
                    field: { onChange, value }
                  }) => (
                    <UploadFile
                      id={uploadItem.id}
                      onChange={(file: ReturnFiles, isCancelRejectUpload) => {
                        onChange(file?.info?.file || '');
                        inputChangeHandler(uploadItem.id, 0, isCancelRejectUpload);
                      }}
                      title={t(uploadItem.title)}
                      fileDescription={t(uploadItem.fileDescription)}
                      sizeLimit={5}
                      formatText={uploadItem.formatText}
                      accept={uploadItem.accept}
                      defaultFileStatus={
                        { fileInfo: getValues(uploadItem.id) }
                      }
                      errorMsg={errors[uploadItem.id]?.message as unknown as string}
                      warningMsg={warningMsg}
                      disabled={checkFieldIsWarning(rejectWarningFields, currentStep, uploadItem.id, 0)}
                      markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, uploadItem.id, value, 0) && !!getValues(uploadItem.id)}
                    />
                  )}
                />
              );
            })}
          </StyledUploadWrap>
        </StyledContentWrap>
      </FormPanel>
    </StyledConfirmedId>
  );
};

export default ConfirmId;
