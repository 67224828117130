export const uploadIdData = [
  {
    id: 'id_front',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'id_back',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'proof_of_address',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'other_documents',
    required: false,
    title: 'otherDocument',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const VFSCTraditional = [
  {
    id: 'id_front',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.png,.pdf,.heic'
  },
  {
    id: 'id_back',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.png,.pdf,.heic'
  }
];

export const onlyPOAUpload = [{
  id: 'proof_of_address',
  required: true,
  title: 'proofAddress',
  fileDescription: 'proofOfAddressDesc',
  sizeLimit: 5,
  formatText: '',
  accept: '.jpg,.png,.pdf,.heic'
}];
