/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
import FormPanel from 'components/FormPanel';
import { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getAccountByID, getTermsAndConditionsForm } from 'api/v1/account';
import CircularProgress from '@mui/material/CircularProgress';
import { useCreateAccountFlow } from 'store/context/hooks';
import { getFieldWarningMessage, checkFieldIsWarning } from 'containers/CreateAccountForm/utils';
import { Countries, CreateAccountSteps, PERSONAL_ACCOUNT_STEPS } from 'constant/createAccount';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorHandler from 'utils/ErrorHandler';
import { useAppDispatch } from 'store';
import { Regulation } from 'type/common';
import { StyledBannerDesp } from '../style';
import {
  StyledTermsAndConditions,
  StyledCheckboxSect,
  StyledCheckboxLabelWrap,
  StyledLoadingWrap,
  StyledScrollWrap,
  StyledTermsAndConditionsContentWrap
} from './style';

interface CheckBoxProps {
  label:string | React.ReactNode;
  id?:string;
  disabled?: boolean
}

export interface ResponseProps {
  id: string;
  panelTitle: string;
  bannerComment: string;
  termsContent: string;
  checkBoxTitle: string;
  data: {
    id: string;
    label: string;
  }[];
}

const Checkbox = forwardRef<HTMLInputElement, CheckBoxProps>((props, ref):JSX.Element => {
  const {
    label, id = '', disabled, ...others
  } = props;
  return (
    <div className="checkbox" aria-label={`${id} checkbox item`}>
      <label className="checkbox-label">
        <div>
          <input
            aria-label={`${id} input`}
            ref={ref}
            type="checkbox"
            disabled={disabled}
            {...others}
          />
          <span className="check-mark" aria-label={`${id} check mark`} />
        </div>
        { label }
      </label>
    </div>
  );
});

const TermsAndConditions = ():JSX.Element => {
  const dispatch = useAppDispatch();
  const { register, getValues } = useFormContext();
  const {
    rejectWarningFields,
    updatePOACertification,
    updateMethod,
    updateSteps
  } = useCreateAccountFlow();
  const [searchParams] = useSearchParams();
  const [termsAndConditions, setTermsAndConditions] = useState<ResponseProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    const country = getValues('country');
    getTermsAndConditionsForm(country).then((res) => {
      if (res?.data) {
        setTermsAndConditions(res.data);
      }

      const accountID = String(localStorage.getItem('accountID'));
      ErrorHandler(getAccountByID(accountID), dispatch).then((accountResponse) => {
        if (accountResponse.status === 200) {
          const curFlow = accountResponse.data.country === Countries.AUS ? Regulation.ASIC : Regulation.VFSC;
          const curMethod = accountResponse.data.jumio_verified === 0 ? 'Traditional' : 'AI';
          updateMethod(curMethod);

          if (curMethod === 'AI' && accountResponse.data.status !== 'Reject') {
            if (curFlow === Regulation.VFSC) { // AI LTD
              if (accountResponse.data.require_poa) {
                updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
                updatePOACertification(true);
              } else {
                updateSteps(PERSONAL_ACCOUNT_STEPS.AIVFSC);
                updatePOACertification(false);
              }
            } else if (accountResponse.data.require_poa) { // AI AU require_poa
              updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
              updatePOACertification(true);
            } else { // AI AU not require_poa
              updatePOACertification(false);
              updateSteps(PERSONAL_ACCOUNT_STEPS.AIASIC);
            }
          } else if (curFlow === Regulation.VFSC) { // Traditional LTD
            if (accountResponse.data.require_poa) {
              updatePOACertification(true);
            } else {
              updatePOACertification(false);
            }
            updateSteps(PERSONAL_ACCOUNT_STEPS.NormalVFSC);
          } else if (accountResponse.data.require_poa) {
            updateSteps(PERSONAL_ACCOUNT_STEPS.AIASICPOA);
            updatePOACertification(true);
          } else {
            updatePOACertification(false);
            updateSteps(PERSONAL_ACCOUNT_STEPS.TraditionASIC);
          }
        }
      }).finally(() => {
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <StyledTermsAndConditions style={{ direction: i18n.language === 'he' ? 'ltr' : 'inherit' }}>
      {!isLoading && termsAndConditions && (
        <>
          <FormPanel id={termsAndConditions.id} title={termsAndConditions.panelTitle}>
            <StyledBannerDesp aria-label="Terms And Conditions Banner Comment">
              {termsAndConditions.bannerComment}
            </StyledBannerDesp>

            <StyledTermsAndConditionsContentWrap className="StyledContentWrap" aria-label="Terms And Conditions Content">
              <StyledScrollWrap
                dangerouslySetInnerHTML={{ __html: termsAndConditions.termsContent }}
              />

            </StyledTermsAndConditionsContentWrap>

          </FormPanel>
          <StyledCheckboxSect>
            <div className="title">
              {termsAndConditions.checkBoxTitle}
            </div>
            {termsAndConditions.data.map((eachTerm:{ id:string; label:string; }) => (
              <Checkbox
                key={eachTerm.id}
                id={eachTerm.id}
                label={(
                  <StyledCheckboxLabelWrap
                    dangerouslySetInnerHTML={{ __html: eachTerm.label }}
                    aria-label={`Terms And Conditions Checkbox Label ${eachTerm.id}`}
                  />
                )}
                {...register(eachTerm.id, {
                  required: searchParams.get('status') === 'Reject'
                    ? !!getFieldWarningMessage(rejectWarningFields, CreateAccountSteps.TermsAndConditions, eachTerm.id, 0)
                    : true
                })}
                disabled={checkFieldIsWarning(rejectWarningFields, CreateAccountSteps.TermsAndConditions, eachTerm.id, 0)}
              />
            ))}
          </StyledCheckboxSect>
        </>
      )}
      {
        isLoading && (
          <StyledLoadingWrap>
            <CircularProgress />
          </StyledLoadingWrap>
        )
      }
    </StyledTermsAndConditions>

  );
};

export default TermsAndConditions;
