import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import FormPanel from 'components/FormPanel';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';
import PhoneInput from 'components/FormCells/PhoneInput';
import DatePicker from 'components/DatePicker';
import { useFormContext, Controller } from 'react-hook-form';
import ErrorHandler from 'utils/ErrorHandler';
import {
  getPersonalDetailForm, getCountryState, getEmploymentFields
} from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import { Countries, CreateCorporateAccountSteps } from 'constant/createAccount';
import PopUp from 'components/PopUp';
import CircularProgress from '@mui/material/CircularProgress';
import { getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting } from 'containers/CreateAccountForm/utils';
import { isArray } from 'lodash';
import langData from 'i18n/langs.json';
import { ResponseFieldsProps, OptionsProps } from 'type/form';
import { StyledLoadingWrap } from 'containers/CreateCorporateAccountForm/components/style';
import { appDayjs } from 'utils/appDayjs.util';
import {
  StyledHalfContentWrap, StyledInputWrap, StyledContentWrap, StyledRadioGroupWrapper, StyledContactPersonDetailsWrap
} from './style';

interface RenderContentProps {
  fields: ResponseFieldsProps[];
  len: number | null;
}

interface SelectHandlerProps {
  onChange: ((...event: unknown[]) => void) | (() => void);
  prev: string;
  next: string;
}

const ContactPersonDetails = (): JSX.Element => {
  const { t, i18n } = useTranslation('registrationForm');
  const {
    control, formState: { errors }, setValue, getValues
  } = useFormContext();
  const {
    rejectWarningFields, rejectStepStatus, sanctionedCountriesInfo, updateRejectStepWarningStatus
  } = useCreateCorporateAccountFlow();
  const [isContactPersonDetailsLoading, setIsContactPersonDetailsLoading] = useState(true);
  const [isEmploymentLoading, setIsEmploymentLoading] = useState(true);
  const [personalForm, setPersonalForm] = useState<ResponseFieldsProps[] | null>(null);
  const [employment, setEmployment] = useState<ResponseFieldsProps[]>([]);
  const [livingState, setLivingState] = useState<OptionsProps[] | null>(null);
  const [phoneValue, setPhoneValue] = useState('');
  const [openSanctionedCountriesModal, setOpenSanctionedCountriesModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const num = personalForm && personalForm.length / 2 - 1;
  const employmentFieldsLength = employment && Math.round(employment.length / 2);
  const countryValue = getValues('country');
  const isAUS = countryValue === Countries.AUS;
  const currentStep = CreateCorporateAccountSteps.CorporateContactPersonDetails;

  const updateCountryState = (country: string): void => {
    const noStateOption = [{ value: 'N/A', label: 'N/A' }];
    setLivingState(noStateOption);
    getCountryState(country).then((res) => {
      if (res.status === 200) {
        const resState = res.data.options.length === 0 ? noStateOption : res.data.options;
        setLivingState(resState);
      }
    });
  };

  const updateEmployment = (country: string): void => {
    setIsEmploymentLoading(true);
    getEmploymentFields(country).then((res) => {
      if (res) {
        setEmployment(res?.data || []);
      }
    }).finally(() => setIsEmploymentLoading(false));
  };

  const selectHandler = ({ onChange, prev, next }: SelectHandlerProps): void => {
    if (prev === next) return;
    onChange(next);
    setValue('state', null);
    if ([prev, next].includes(Countries.AUS)) setValue('dob', null);
    updateCountryState(next);
  };

  const inputChangeHandler = (id: string, index = 0): void => {
    if (searchParams.get('status') !== 'Reject') return;
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep]) && rejectWarningFields[currentStep][index][id]) {
      if (getValues(id) === rejectWarningFields[currentStep][index][id].warning_value) {
        if (!rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      } else {
        for (let j = 0; j < rejectWarningFields[currentStep].length; j += 1) {
          const fieldsArr = Object.keys(rejectWarningFields[currentStep][j]);
          for (let i = 0; i < fieldsArr.length; i += 1) {
            if (rejectWarningFields[currentStep][j][fieldsArr[i]].warning_value === getValues(fieldsArr[i])) {
              if (!rejectStepStatus[currentStep].isWarning) {
                updateRejectStepWarningStatus(currentStep, true);
              }
              return;
            }
          }
        }
        if (rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, false);
        }
      }
    }
  };

  useEffect(() => {
    ErrorHandler(getPersonalDetailForm(), dispatch).then((data) => {
      if (data.status === 200) {
        setPersonalForm(data.data);
      }
    });
    updateEmployment(countryValue);
    const isSanctionedCountry = sanctionedCountriesInfo.countries.includes(countryValue);

    if (isSanctionedCountry) {
      setValue('country', '');
    }

    if (!isSanctionedCountry) {
      updateCountryState(countryValue);
      setIsContactPersonDetailsLoading(false);
    } else setIsContactPersonDetailsLoading(false);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderInputCell = (item: ResponseFieldsProps, index: number): JSX.Element => {
    switch (item.type) {
      case 'input':
        return (
          <StyledInputWrap key={item.id} isHalfWidth={item.id === 'zip_code'}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{
                ...item.rules,
                pattern: item?.rules?.pattern && {
                  value: new RegExp(item.rules?.pattern.value),
                  message: item.rules?.pattern.message
                }
              }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <Input
                  label={item.name}
                  height="48px"
                  placeholder={item.placeHolder}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(item.id);
                  }}
                  value={value || ''}
                  errorMsg={errors[item.id] && (errors[item.id]?.message as unknown as string)}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                  id={item.id}
                />
              )}
            />
          </StyledInputWrap>
        );
      case 'select':
        return (
          <StyledInputWrap key={item.id} isHalfWidth={item.id === 'state'}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => {
                if (!value && item.default) setValue(item.id, item.default);

                return (
                  <Select
                    label={item.name}
                    options={item.id === 'state' && livingState ? livingState : item.options}
                    inputHeight="48px"
                    onChange={(val) => {
                      if (item.id === 'country') {
                        selectHandler({ onChange, next: val, prev: value });
                      } else {
                        onChange(val);
                      }
                      inputChangeHandler(item.id);
                    }}
                    menuPlacement={item.id === 'state' || item.id === 'nationality' ? 'top' : 'bottom'}
                    currentValue={value}
                    defaultValue={item.default}
                    placeholder={item.placeHolder}
                    errorMsg={errors[item.id] && 'Error'}
                    warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                    disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                    markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                    id={item.id}
                  />
                );
              }}
            />
          </StyledInputWrap>
        );
      case 'phone':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange, value
                }
              }) => (
                <PhoneInput
                  id={item.id}
                  label={item.name}
                  height="48px"
                  onChange={(phoneVal: string, info: { [key: string]: string }) => {
                    onChange(phoneVal);
                    if (phoneVal === info.dialCode) {
                      setValue('mobile', null);
                      setPhoneValue(info.dialCode);
                    }
                    inputChangeHandler(item.id);
                  }}
                  defaultValue={value || phoneValue}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                  localization={langData[i18n.language as keyof typeof langData]}
                />
              )}
            />
          </StyledInputWrap>
        );
      case 'date':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <DatePicker
                  disableFuture
                  id={item.id}
                  label={item.name}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(item.id);
                  }}
                  defaultValue={value}
                  defaultCalendarMonth={appDayjs().subtract(18, 'year')}
                  maxDate={appDayjs().subtract(18, 'year')}
                  minDate={isAUS ? appDayjs().subtract(85, 'year') : undefined}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, value)}
                />
              )}
            />
          </StyledInputWrap>
        );
      case 'radioButton':
        return (
          <StyledInputWrap key={item.id}>
            <Controller
              control={control}
              name={item.id}
              rules={{ required: item?.rules?.required?.value }}
              render={({
                field: {
                  onChange, value
                }
              }) => (
                <StyledRadioGroupWrapper>
                  <RadioGroup
                    options={item.options}
                    id={item.id}
                    groupName={item.id}
                    label={item.name}
                    optionType="button"
                    onChange={onChange}
                    value={value}
                    disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
                  />
                  <div className="optionBtnErr">
                    {errors[item.id] && 'Error'}
                  </div>
                </StyledRadioGroupWrapper>
              )}
            />
          </StyledInputWrap>
        );
      default:
        return (
          <StyledInputWrap key={item.id}>
            <Input
              id={item.id}
              label={item.name}
              height="48px"
              placeholder={item.placeHolder}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
            />
          </StyledInputWrap>
        );
    }
  };

  const renderContent = ({ fields, len }: RenderContentProps): JSX.Element => (
    <>
      <StyledHalfContentWrap>
        {fields.map((item: ResponseFieldsProps, index: number) => {
          if (len && len > index) {
            return renderInputCell(item, index);
          }
          return null;
        })}
      </StyledHalfContentWrap>
      <StyledHalfContentWrap>
        {fields.map((item: ResponseFieldsProps, index: number) => {
          if (len && len <= index) {
            return renderInputCell(item, index);
          }
          return null;
        })}
      </StyledHalfContentWrap>
    </>
  );

  return (
    <StyledContactPersonDetailsWrap>
      <FormPanel title={t('Contact Person Details')} id="Contact Person Details">
        <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
          {personalForm && !isContactPersonDetailsLoading ? (
            renderContent({ fields: personalForm, len: num })
          ) : (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )}
        </StyledContentWrap>
        <PopUp
          content={sanctionedCountriesInfo.message}
          openModal={openSanctionedCountriesModal}
        >
          <Button aria-label="Pop Up OK" onClick={() => setOpenSanctionedCountriesModal(false)}>
            {t('ok')}
          </Button>
        </PopUp>
      </FormPanel>
      <FormPanel title={t('employment')} id="Employment">
        <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
          {employment && !isEmploymentLoading ? (
            renderContent({ fields: employment, len: employmentFieldsLength })
          ) : (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )}
        </StyledContentWrap>
      </FormPanel>
    </StyledContactPersonDetailsWrap>
  );
};

export default ContactPersonDetails;
