/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import FormPanel from 'components/FormPanel';
import UploadFile, { ReturnFiles } from 'components/UploadFileWithAPI';
import { useFormContext, Controller } from 'react-hook-form';
import {
  shareholderDataVFSC,
  shareholderData,
  directorDataVFSC,
  directorData,
  companyDocuments,
  contactPersonVFSC,
  contactPerson
} from 'containers/CreateCorporateAccountForm/data/registration';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import { CreateCorporateAccountSteps } from 'constant/createAccount';
import { useTranslation } from 'react-i18next';
import {
  getUploadWarningMessage,
  checkUploadWarning,
  checkUploadCorrecting,
  RejectWarningUploadOneByOne
} from 'containers/CreateAccountForm/utils';
import { useSearchParams } from 'react-router-dom';
import ErrorHandler from 'utils/ErrorHandler';
import { getFileList } from 'api/v1/document';
import { useAppDispatch } from 'store';
import { isArray, isEmpty } from 'lodash';
import { Regulation } from 'type/common';
import {
  StyledConfirmedId,
  StyledUploadWrap,
  StyledSectionTitle,
  StyledConfirmedCompany,
  StyledIdWrap
} from './style';
import { StyledContentWrap } from '../style';

interface IPerson {
  city: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  mobile: string;
  nationality: string;
  photo_id_number: string;
  residential_address: string;
  state: string;
  zip_code: string;
  share_percentage? :string;
  shareholder_id?: number;
  director_id?: number;
}

const initFileList = {
  documents: [
    {
      name: '',
      size: 0,
      document_id: undefined,
      file_path: '',
      type: ''
    }
  ],
  company: {
    documents: [
      {
        name: '',
        size: 0,
        document_id: undefined,
        file_path: '',
        type: ''
      }
    ]
  },
  shareholders: [
    {
      shareholder_id: 0,
      name: '',
      size: 0,
      document_id: undefined,
      file_path: '',
      type: ''
    }
  ],
  directors: [
    {
      director_id: 0,
      name: '',
      size: 0,
      document_id: undefined,
      file_path: '',
      type: ''
    }
  ]
};

const ConfirmId = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const { control, formState: { errors }, getValues } = useFormContext();
  const currentStep = CreateCorporateAccountSteps.CorporateConfirmID;
  const shareholder: IPerson[] = getValues('shareholders');
  const director: IPerson[] = getValues('directors');
  const contactPersonFirstName: string = getValues('first_name');
  const contactPersonLastName: string = getValues('last_name');
  const [fileList, setFileList] = useState(initFileList);
  const {
    flow, rejectWarningFields, updateRejectStepWarningStatus
  } = useCreateCorporateAccountFlow();
  const shareholderUpload = flow === Regulation.VFSC ? shareholderDataVFSC : shareholderData;
  const directorUpload = flow === Regulation.VFSC ? directorDataVFSC : directorData;
  const contactPerosnUpload = flow === Regulation.VFSC ? contactPersonVFSC : contactPerson;
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const accountID = String(localStorage.getItem('accountID'));

    ErrorHandler(
      getFileList(accountID),
      dispatch
    )
      .then((res) => {
        if (res && res.status === 200) {
          setFileList(res.data);
        }
      });
  }, []);
  const inputChangeHandler = (): void => {
    if (searchParams.get('status') !== 'Reject') return;
    let warning = false;
    if (isArray(rejectWarningFields[currentStep])) {
      const rejection = rejectWarningFields[currentStep][0] as RejectWarningUploadOneByOne;
      Object.keys(rejection).forEach((key) => {
        if (key === 'company') {
          const rejectArray = rejection[key]?.documents;
          const currentfiles = getValues(key);
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((doc: { warning_value: string; type: string}) => {
              const filterData = currentfiles?.documents?.filter((x : { type: string }) => x.type === doc.type);
              if (isEmpty(filterData) || (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path)) {
                warning = true;
              }
            });
          }
        } else if (key === 'shareholders') {
          const rejectArray = rejection[key];
          const currentfiles = getValues(key);
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((user) => {
              const currentUser = currentfiles.filter((x: { shareholder_id: number; }) => x.shareholder_id === user?.shareholder_id);
              user?.documents.forEach((doc: { warning_value: string; type: string}) => {
                const filterData = currentUser[0]?.documents?.filter((x : { type: string }) => x.type === doc.type);
                if (isEmpty(filterData) || (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path)) {
                  warning = true;
                }
              });
            });
          }
        } else if (key === 'directors') {
          const rejectArray = rejection[key];
          const currentfiles = getValues(key);
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((user) => {
              const currentUser = currentfiles.filter((x: { director_id: number; }) => x.director_id === user?.director_id);
              user?.documents.forEach((doc: { warning_value: string; type: string}) => {
                const filterData = currentUser[0]?.documents?.filter((x : { type: string }) => x.type === doc.type);
                if (isEmpty(filterData) || (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path)) {
                  warning = true;
                }
              });
            });
          }
        } else if (key === 'contactPerson') {
          const rejectArray = rejection[key]?.documents;
          const currentfiles = getValues('documents');
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((doc: { document_id: number; warning_value: string; type: string}) => {
              const filterData = currentfiles?.filter((x : { type: string }) => x.type === doc.type);
              if (isEmpty(filterData) || (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path)) {
                warning = true;
              }
            });
          }
        }
      });
    }

    updateRejectStepWarningStatus(currentStep, warning);
  };

  return (
    <>
      <StyledConfirmedCompany>
        <FormPanel id="upload-company-documents" title={t('companyDocuments')}>
          <StyledContentWrap>
            <StyledUploadWrap>
              {companyDocuments.map((uploadItem) => {
                const warningMsg = getUploadWarningMessage(rejectWarningFields[currentStep], 'company', uploadItem.id, 0);
                return (
                  <Controller
                    key={uploadItem.id}
                    control={control}
                    name={uploadItem.id}
                    rules={{ required: warningMsg || searchParams.get('status') !== 'Reject' ? uploadItem.required : false }}
                    render={({
                      field: { onChange, value }
                    }) => (
                      <UploadFile
                        id={uploadItem.id}
                        onChange={(file: ReturnFiles) => {
                          onChange(file?.info?.file || '');
                          inputChangeHandler();
                        }}
                        title={t(uploadItem.title)}
                        fileDescription={t(uploadItem.fileDescription)}
                        sizeLimit={5}
                        formatText={uploadItem.formatText}
                        accept={uploadItem.accept}
                        files={fileList.company.documents}
                        defaultFileStatus={
                          { fileInfo: getValues(uploadItem.id) }
                        }
                        targetID={0}
                        targetName="company"
                        errorMsg={errors[uploadItem.id]?.message as unknown as string}
                        warningMsg={warningMsg}
                        disabled={checkUploadWarning(rejectWarningFields[currentStep], 'company', uploadItem.id, 0)}
                        markWarning={checkUploadCorrecting(rejectWarningFields[currentStep], 'company', uploadItem.id, value, 0)}
                        setFileList={setFileList}
                      />
                    )}
                  />
                );
              })}
            </StyledUploadWrap>
          </StyledContentWrap>
        </FormPanel>
      </StyledConfirmedCompany>
      <StyledConfirmedId>
        <FormPanel id="upload-id-documents" title={t('ID Documents')}>
          <StyledContentWrap>
            <StyledIdWrap lastItem={false}>
              <StyledSectionTitle aria-label="Contact Person Title">
                {`${t('contactPerson')}: ${contactPersonFirstName} ${contactPersonLastName}`}
              </StyledSectionTitle>
              <StyledUploadWrap>
                {contactPerosnUpload.map((uploadItem) => {
                  const warningMsg = getUploadWarningMessage(rejectWarningFields[currentStep], 'contactPerson', uploadItem.id, 0);
                  return (
                    <Controller
                      key={`${uploadItem.id}`}
                      control={control}
                      name={`${uploadItem.id}`}
                      rules={{ required: warningMsg || searchParams.get('status') !== 'Reject' ? uploadItem.required : false }}
                      render={({
                        field: { onChange, value }
                      }) => (
                        <UploadFile
                          id={uploadItem.id}
                          onChange={(file: ReturnFiles) => {
                            onChange(file?.info?.file || '');
                            inputChangeHandler();
                          }}
                          title={t(uploadItem.title)}
                          fileDescription={t(uploadItem.fileDescription)}
                          sizeLimit={5}
                          formatText={uploadItem.formatText}
                          accept={uploadItem.accept}
                          files={fileList.documents}
                          targetID={0}
                          targetName="contactPerson"
                          defaultFileStatus={
                            { fileInfo: getValues(uploadItem.id) }
                          }
                          errorMsg={errors[uploadItem.id]?.message as unknown as string}
                          warningMsg={warningMsg}
                          disabled={checkUploadWarning(rejectWarningFields[currentStep], 'contactPerson', uploadItem.id, 0)}
                          markWarning={checkUploadCorrecting(rejectWarningFields[currentStep], 'contactPerson', uploadItem.id, value, 0)}
                          setFileList={setFileList}
                        />
                      )}
                    />
                  );
                })}
              </StyledUploadWrap>
            </StyledIdWrap>

            {shareholder.map((x, index) => (
              <StyledIdWrap key={index} lastItem={false}>
                <StyledSectionTitle aria-label={`Shareholder ${index + 1} Title`}>
                  {`${t('shareholder')}: ${x.first_name || contactPersonFirstName} ${x.last_name || contactPersonLastName}`}
                </StyledSectionTitle>
                <StyledUploadWrap>
                  {shareholderUpload.map((uploadItem) => {
                    const warningMsg = getUploadWarningMessage(rejectWarningFields[currentStep], 'shareholders', uploadItem.id, x.shareholder_id);
                    return (
                      <Controller
                        key={`${uploadItem.id}_${index}`}
                        control={control}
                        name={`${uploadItem.id}_${index}`}
                        rules={{ required: warningMsg || searchParams.get('status') !== 'Reject' ? uploadItem.required : false }}
                        render={({
                          field: { onChange, value }
                        }) => (
                          <UploadFile
                            id={uploadItem.id}
                            onChange={(file: ReturnFiles) => {
                              onChange(file?.info?.file || '');
                              inputChangeHandler();
                            }}
                            title={t(uploadItem.title)}
                            fileDescription={t(uploadItem.fileDescription)}
                            sizeLimit={5}
                            formatText={uploadItem.formatText}
                            accept={uploadItem.accept}
                            files={fileList.shareholders}
                            targetID={x.shareholder_id || 0}
                            targetName="shareholder"
                            defaultFileStatus={
                              { fileInfo: getValues(`shareholder_${uploadItem.id}_${index}`) }
                            }
                            errorMsg={errors[`shareholder_${uploadItem.id}_${index}`]?.message as unknown as string}
                            warningMsg={warningMsg}
                            disabled={checkUploadWarning(rejectWarningFields[currentStep], 'shareholders', uploadItem.id, x.shareholder_id)}
                            markWarning={checkUploadCorrecting(
                              rejectWarningFields[currentStep],
                              'shareholders',
                              uploadItem.id,
                              value,
                              x.shareholder_id
                            )}
                            setFileList={setFileList}
                          />
                        )}
                      />
                    );
                  })}
                </StyledUploadWrap>
              </StyledIdWrap>
            ))}
            {director.map((x, index) => (
              <StyledIdWrap key={index} lastItem={index + 1 === director.length}>
                <StyledSectionTitle aria-label={`Director ${index + 1} Title`}>
                  {`${t('director')}: ${x.first_name || contactPersonFirstName} ${x.last_name || contactPersonLastName}`}
                </StyledSectionTitle>
                <StyledUploadWrap>
                  {directorUpload.map((uploadItem) => {
                    const warningMsg = getUploadWarningMessage(rejectWarningFields[currentStep], 'directors', uploadItem.id, x.director_id);
                    return (
                      <Controller
                        key={`${uploadItem.id}_${index}`}
                        control={control}
                        name={`${uploadItem.id}_${index}`}
                        rules={{ required: warningMsg || searchParams.get('status') !== 'Reject' ? uploadItem.required : false }}
                        render={({
                          field: { onChange, value }
                        }) => (
                          <UploadFile
                            id={uploadItem.id}
                            onChange={(file: ReturnFiles) => {
                              onChange(file?.info?.file || '');
                              inputChangeHandler();
                            }}
                            title={t(uploadItem.title)}
                            fileDescription={t(uploadItem.fileDescription)}
                            sizeLimit={5}
                            formatText={uploadItem.formatText}
                            accept={uploadItem.accept}
                            files={fileList.directors}
                            targetID={x.director_id || 0}
                            targetName="director"
                            defaultFileStatus={
                              { fileInfo: getValues(`shareholder_${uploadItem.id}_${index}`) }
                            }
                            errorMsg={errors[`shareholder_${uploadItem.id}_${index}`]?.message as unknown as string}
                            warningMsg={warningMsg}
                            disabled={checkUploadWarning(rejectWarningFields[currentStep], 'directors', uploadItem.id, x.director_id)}
                            markWarning={checkUploadCorrecting(
                              rejectWarningFields[currentStep],
                              'directors',
                              uploadItem.id,
                              value,
                              x.director_id
                            )}
                            setFileList={setFileList}
                          />
                        )}
                      />
                    );
                  })}
                </StyledUploadWrap>
              </StyledIdWrap>
            ))}
          </StyledContentWrap>
        </FormPanel>
      </StyledConfirmedId>
    </>
  );
};

export default ConfirmId;
