import Select, { StylesConfig } from 'react-select';
import IconComponent from 'components/Icons';
import { Languages } from 'constant/language';
import {
  StyledWrap, StyledIconWrap, StyledErrMsg, StyledWarningMsg, StyledNotAllowWrap
} from './style';

interface SelectProps {
  options?: OptionsProps[];
  label?: string;
  errorMsg?: string;
  markWarning?: boolean;
  warningMsg?: string | string[];
  inputHeight?: string;
  className?: string;
  currentValue?: string;
  defaultValue?: string;
  placeholder?:string | React.ReactNode;
  onChange?: (value: string) => void;
  menuIsOpen?:boolean;
  menuPlacement?: 'bottom' | 'auto' | 'top';
  disabled?:boolean;
  classNamePrefix?:string;
  id?:string;
  downIconName?:string;
}

interface OptionsProps {
  value: string,
  label: string
}

const DropdownIndicator = ():JSX.Element => (
  <StyledIconWrap>
    <IconComponent name="FilledArrowDropDown" />
  </StyledIconWrap>
);
const DownArrowIndicator = ():JSX.Element => (
  <StyledIconWrap>
    <IconComponent name="DownArrow" />
  </StyledIconWrap>
);

const CustomSelect = (props: SelectProps):JSX.Element => {
  const {
    options = [], label, errorMsg, inputHeight, className, currentValue, defaultValue,
    onChange, disabled, classNamePrefix, warningMsg, markWarning, menuIsOpen, menuPlacement,
    placeholder, id = '', downIconName = 'FilledArrowDropDown', ...others
  } = props;

  const currentLang = localStorage.getItem('language') || Languages.EN;

  const selectStyle: StylesConfig<OptionsProps> = {
    control: (styles, state) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const borderColorStyles = (): any => {
        if (errorMsg) return { borderColor: '#E92C2C !important' };
        if (!errorMsg && warningMsg && warningMsg.length > 0 && !markWarning) {
          return { borderColor: '#F98600 !important' };
        }
        if (state.isFocused) return { borderColor: '#006ACC !important' };
        return {
          borderColor: '#BBBBBB',
          '&:hover': {
            borderColor: '#5C5C5C'
          }
        };
      };

      const styleObj = {
        ...styles,
        height: inputHeight,
        fontSize: '14px',
        borderWidth: '1px',
        borderStyle: 'solid',
        ...borderColorStyles(),
        borderRadius: '8px',
        backgroundColor: disabled ? '#FCFCFC' : 'inherit',
        cursor: 'pointer',
        boxShadow: 'none'
      };
      return styleObj;
    },

    valueContainer: (styles) => ({
      ...styles,
      padding: '0 16px'
    }),
    input: (styles) => ({
      ...styles,
      margin: 0
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: '8px'
    }),

    indicatorSeparator: () => ({ display: 'none' }),
    indicatorsContainer: (base) => ({
      ...base,
      margin: ((currentLang !== 'ar' && currentLang !== 'he') ? '0 10px 0 0' : '0 0 0 10px')
    }),
    singleValue: (styles) => ({
      ...styles,
      color: disabled ? '#BBBBBB' : '#2A2A2A',
      margin: 0
    }),

    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? '#CCE6F6' : '#fff',
      borderRadius: '8px',
      margin: '6px auto',
      width: '95%',
      color: '#1C1C1C',
      '&:hover': {
        backgroundColor: '#CCE6F6',
        cursor: 'pointer'
      }
    })

  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeHandler = (option:any):void => {
    if (onChange) onChange(option?.value || '');
  };

  const renderWarningMsg = ():React.ReactNode => {
    if (Array.isArray(warningMsg)) {
      return warningMsg.map((eachMsg:string) => (
        <StyledWarningMsg key={eachMsg} markWarning={markWarning} aria-label={`${label} Error Message`}>
          {eachMsg}
        </StyledWarningMsg>
      ));
    }
    return (
      <StyledWarningMsg markWarning={markWarning} aria-label={`${label} Error Message`}>
        {warningMsg}
      </StyledWarningMsg>
    );
  };

  return (
    <StyledWrap label={label} className={className} disabled={disabled} aria-label={`${id} Select Wrap`}>
      <StyledNotAllowWrap disabled={disabled}>
        {label && <label htmlFor={id} aria-label={`${id} label`}>{label}</label>}
        <Select
          aria-label={`${id} Select Input`}
          classNamePrefix={classNamePrefix}
          menuIsOpen={menuIsOpen}
          menuPlacement={menuPlacement}
          options={options}
          styles={selectStyle}
          onChange={changeHandler}
          placeholder={placeholder}
          className="select"
          components={{ DropdownIndicator: downIconName === 'DownArrow' ? DownArrowIndicator : DropdownIndicator }}
          value={options.find(({ value }) => value === currentValue)}
          defaultValue={options.find(({ value }) => value === defaultValue)}
          {...others}
        />

        <div className="select-msg-wrap">
          {errorMsg && (
          <StyledErrMsg aria-label={`${id} Select Error Message`}>
            {errorMsg}
          </StyledErrMsg>
          )}
          { warningMsg && renderWarningMsg()}
        </div>
      </StyledNotAllowWrap>

    </StyledWrap>

  );
};

export default CustomSelect;
